import React from "react";
import DefaultImage from "./Imagens/Ebitech3-logo-quad.png";

const FotoEquipamentos = ({ equipamento }) => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "5px",
    borderRadius: "5px",
    marginTop: "85px",
    marginLeft: "5px",
  };

  const imageStyle = {
    width: "100%",
    maxHeight: "500px",
    objectFit: "contain",
    borderRadius: "20px",
  };

  if (!equipamento) {
    return (
      <div style={containerStyle}>
        <img src={DefaultImage} alt="Default Equipment" style={imageStyle} />
      </div>
    );
  }

  return (
    <div style={containerStyle}>
      <img
        src={equipamento.imageUrl || DefaultImage}
        alt={equipamento.nome || "Equipamento"}
        style={imageStyle}
      />
    </div>
  );
};

export default FotoEquipamentos;
