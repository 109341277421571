import React, { useState } from "react";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ExcelIcon from "./Imagens/excel.png";
import PdfIcon from "./Imagens/pdf.png";
import PriIcon from "./Imagens/primeira.png";
import AntIcon from "./Imagens/anterior.png";
import ProIcon from "./Imagens/proxima.png";
import UltIcon from "./Imagens/ultima.png";

const tableCellStyle = {
  textAlign: "center",
  verticalAlign: "middle",
};

const formatarDataParaBR = (data) => {
  const [ano, mes, dia] = data.split('-');
  return `${dia}-${mes}-${ano}`;
};

const iconStyle = {
    marginRight: "10px",
    width: "25px",
    height: "25px",
};

const iconPagesStyle = {
  width: "25px",
  height: "25px",
};

const TabelaRelatorios = ({ dados, nomeEquipamento, dataInicial, dataFinal }) => {
  const [linhasPorPagina, setLinhasPorPagina] = useState(50);
  const [paginaAtual, setPaginaAtual] = useState(1);

  const handleLinhasPorPaginaChange = (e) => {
    setLinhasPorPagina(parseInt(e.target.value, 10));
    setPaginaAtual(1);
  };

  const totalPaginas = Math.ceil(dados.length / linhasPorPagina);

  const dadosPaginados = dados.slice(
    (paginaAtual - 1) * linhasPorPagina,
    paginaAtual * linhasPorPagina
  );

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dados);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Relatório");
    const nomeArquivo = `${nomeEquipamento}_${dataInicial}_${dataFinal}.xlsx`;
    XLSX.writeFile(workbook, nomeArquivo);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text("Relatório", 20, 10);
    doc.autoTable({
      head: [["Bateria", "Data", "Hora"]],
      body: dados.map((dado) => [dado.bv, dado.data, dado.hora]),
    });
    const nomeArquivo = `${nomeEquipamento}_${dataInicial}_${dataFinal}.pdf`;
    doc.save(nomeArquivo);
  };

  const renderPaginas = () => {
    const paginas = [];
    let startPage = Math.max(1, paginaAtual - 3);
    let endPage = Math.min(totalPaginas, paginaAtual + 3);

    if (paginaAtual <= 4) {
      startPage = 1;
      endPage = Math.min(7, totalPaginas);
    } else if (paginaAtual + 3 >= totalPaginas) {
      startPage = Math.max(totalPaginas - 6, 1);
      endPage = totalPaginas;
    }

    for (let i = startPage; i <= endPage; i++) {
      paginas.push(
        <button
          key={i}
          onClick={() => setPaginaAtual(i)}
          style={{ margin: '0 2px', padding: '5px 10px', backgroundColor: paginaAtual === i ? '#007bff' : '#ffffff', color: paginaAtual === i ? '#ffffff' : '#000000' }}
        >
          {i}
        </button>
      );
    }
    return paginas;
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center", gap: "10px", marginBottom: "10px", marginTop: "10px", }}>
        <button onClick={exportToExcel}>
        <img src={ExcelIcon} style={iconStyle} alt="Excel Icon"/> Excel
        </button>
        <button onClick={exportToPDF}>
        <img src={PdfIcon} style={iconStyle} alt="Pdf Icon"/> PDF
        </button>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <label>
          Linhas por página:
          <select value={linhasPorPagina} onChange={handleLinhasPorPaginaChange}>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={150}>150</option>
            <option value={200}>200</option>
          </select>
        </label>
      </div>
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th style={tableCellStyle}>Bateria</th>
            <th style={tableCellStyle}>Data</th>
            <th style={tableCellStyle}>Hora</th>
          </tr>
        </thead>
        <tbody>
          {dadosPaginados.map((dado, index) => (
            <tr key={index}>
              <td style={tableCellStyle}>{dado.bv}</td>
              <td style={tableCellStyle}>{formatarDataParaBR(dado.data)}</td>
              <td style={tableCellStyle}>{dado.hora}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ display: "flex", justifyContent: "center", gap: "10px", alignItems: "center", marginTop: "10px" }}>
        <button onClick={() => setPaginaAtual(1)} disabled={paginaAtual === 1}>
          <img src={PriIcon} style={iconPagesStyle} alt="Primeira Icon"/> Primeira
        </button>
        <button onClick={() => setPaginaAtual(paginaAtual - 1)} disabled={paginaAtual === 1}>
          <img src={AntIcon} style={iconPagesStyle} alt="Anterior Icon"/> Anterior
        </button>
        {renderPaginas()}
        <button onClick={() => setPaginaAtual(paginaAtual + 1)} disabled={paginaAtual === totalPaginas}>
          <img src={ProIcon} style={iconPagesStyle} alt="Próxima Icon"/> Próxima
        </button>
        <button onClick={() => setPaginaAtual(totalPaginas)} disabled={paginaAtual === totalPaginas}>
          <img src={UltIcon} style={iconPagesStyle} alt="Última Icon"/> Última
        </button>
      </div>
    </div>
  );
};

export default TabelaRelatorios;
