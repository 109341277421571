import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import LogoIcon from "./Imagens/Ebitech3-logo.png";
import "bootstrap/dist/css/bootstrap.min.css";

const Alertas = ({ usuario }) => {
  const [equipamentos, setEquipamentos] = useState([]);
  // const [bvMin, setBvMin] = useState(11.3); // Valores padrão
  // const [bvOk, setBvOk] = useState(11.8);
  // const [comMin, setComMin] = useState(30);
  // const [comAlert, setComAlert] = useState(180);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  // const ec2Url = process.env.NODE_ENV === 'development'
  //   ? process.env.REACT_APP_API_URL_DEV
  //   : process.env.REACT_APP_API_URL_PROD;

  useEffect(() => {
    // fetch(`http://3.12.177.212/equipamentos.json`)
    // fetch(`${ec2Url}/equipamentos.json`)
    fetch(`https://ec2.ebitech.com.br/equipamentos_ebitech.json`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch equipment data");
        }
        return response.json();
      })
      .then((data) => {
        const equipamentosAutorizados = data.filter((equip) =>
          equip.users.includes(usuario.username)
        );
        setEquipamentos(equipamentosAutorizados);
      })
      .catch((error) => {
        console.error("Erro ao carregar os equipamentos:", error);
        setError(error.message);
      });
  }, [usuario]);
  // }, [usuario, ec2Url]);

  const containerStyle = {
    padding: "20px",
    maxWidth: "flex",
    margin: "0 auto 0 200px",
  };

  const tableCellStyle = {
    textAlign: "center", // Alinha o texto ao centro
    verticalAlign: "middle", // Alinha verticalmente ao meio
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setIsLoading(true);
  //   const data = {
  //     bv_min: bvMin,
  //     bv_ok: bvOk,
  //     com_min: comMin,
  //     com_alert: comAlert,
  //   };

  //   try {
  //     const response = await fetch("http://3.12.177.212/update_alert_params", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(data),
  //     });

  //     if (!response.ok) {
  //       throw new Error(`HTTP status ${response.status}`);
  //     }

  //     const result = await response.json();
  //     alert(result.message);
  //   } catch (error) {
  //     console.error("Erro ao enviar os dados:", error);
  //     setError("Failed to update parameters: " + error.message);
  //   }
  //   setIsLoading(false);
  // };

  // Filtra e mapeia os equipamentos para alertas com base no tempo de última comunicação
  const alertas = equipamentos
    .map((equip) => {
      const lastCommunication = DateTime.fromISO(`${equip.data}T${equip.hora}`);
      const now = DateTime.now();
      const diff = now.diff(lastCommunication, "minutes").minutes;

      let status;
      if (diff <= 30) {
        status = "Ativo";
      } else if (diff > 30 && diff <= 180) {
        status = "Alerta";
      } else {
        status = "Sem Comunicação";
      }

      return { ...equip, status, diff };
    })
    .filter((equip) => equip.status !== "Ativo");

  return (
    <div style={containerStyle}>
    <img
        src={LogoIcon}
        alt="Logo"
        style={{
          position: "absolute",
          top: 20,
          left: 5,
          width: "144px",
          height: "40px",
        }}
      />
      {error && <p style={{ color: "red" }}>{error}</p>}
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          {/* <h1 style={{ textAlign: "center" }}> 
            Edição dos Alertas no Telegram
          </h1>
          <div style={{ margin: "20px 0" }} />
          <form onSubmit={handleSubmit}>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <label className="col-4">Tensão Mínima da Bateria: </label>
                <input
                  className="col-1"
                  type="number"
                  value={bvMin}
                  onChange={(e) => setBvMin(parseFloat(e.target.value))}
                  step="0.1"
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <label className="col-4">Tensão Alerta da Bateria: </label>
                <input
                  className="col-1"
                  type="number"
                  value={bvOk}
                  onChange={(e) => setBvOk(parseFloat(e.target.value))}
                  step="0.1"
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <label className="col-4">
                  Tempo Mínimo de Alerta de Comunicação (min):{" "}
                </label>
                <input
                  className="col-1"
                  type="number"
                  value={comMin}
                  onChange={(e) => setComMin(parseInt(e.target.value))}
                  step="1"
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <label className="col-4">
                  Tempo Máximo de Alerta Sem Comunicação (min):{" "}
                </label>
                <input
                  className="col-1"
                  type="number"
                  value={comAlert}
                  onChange={(e) => setComAlert(parseInt(e.target.value))}
                  step="1"
                />
                <button type="submit" style={{ marginLeft: "10px" }}>
                  Atualizar
                </button>
              </div>
            </div>
          </form>*/}

          <div style={{ margin: "40px 0" }} />
          <h1 style={{ textAlign: "center" }}>Alertas de Equipamentos</h1>
          <table className="table">
            <thead>
              <tr>
                <th style={tableCellStyle}>Nome</th>
                <th style={tableCellStyle}>Status</th>
                <th style={tableCellStyle}>Última Comunicação</th>
                {/* <th>Tempo Decorrido (min)</th> */}
              </tr>
            </thead>
            <tbody>
              {alertas.map((alerta, index) => (
                <tr key={index}>
                  <td style={tableCellStyle}>{alerta.nome}</td>
                  <td style={tableCellStyle}>{alerta.status}</td>
                  <td
                    style={tableCellStyle}
                  >{`${alerta.data} ${alerta.hora}`}</td>
                  {/* <td>{alerta.diff}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default Alertas;
