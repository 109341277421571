import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoIcon from "./Imagens/Ebitech3-logo.png";

function Cadastro() {
  const [newUsername, setNewUsername] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [email, setEmail] = useState("");
  const [nome, setNome] = useState("");
  const [cidade, setCidade] = useState("");
  const navigate = useNavigate();

  const handleCadastro = async (e) => {
    e.preventDefault();
  
    if (!newUsername || !newPassword || !email || !nome || !cidade) {
      alert('Todos os campos são obrigatórios!');
      return;
    }
  
    console.log('Enviando dados:', { username: newUsername, password: newPassword, email, nome, cidade });
  
    try {
      const response = await fetch('https://ec2.ebitech.com.br/cadastro/cadastro', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: newUsername, password: newPassword, email, nome, cidade }),
      });
  
      // Obter a resposta como texto para inspecionar o conteúdo
      const responseText = await response.text();
      console.log('Resposta completa do servidor:', responseText);
  
      if (!response.ok) {
        throw new Error(`Erro na requisição: ${response.statusText}`);
      }
  
      // Tente parsear a resposta de texto como JSON
      const responseData = JSON.parse(responseText);
      console.log('Resposta do servidor como JSON:', responseData);
  
      alert('Cadastro completo!');
      navigate("/login");
  
    } catch (error) {
      console.error('Erro na requisição:', error);
      alert('Erro ao cadastrar usuário. Por favor, tente novamente.');
    }
  };
  
  return (
    <div style={styles.container}>
      <img src={LogoIcon} alt="Logo" style={{ width: "auto", height: "150px", marginBottom: "20px" }} />
      <h2>Cadastro</h2>
      <form onSubmit={handleCadastro} style={styles.form}>
        <input
          type="text"
          value={newUsername}
          onChange={(e) => setNewUsername(e.target.value)}
          placeholder="Novo Usuário"
          style={styles.input}
        />
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="Nova Senha"
          style={styles.input}
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          style={styles.input}
        />
        <input
          type="text"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          placeholder="Nome"
          style={styles.input}
        />
        <input
          type="text"
          value={cidade}
          onChange={(e) => setCidade(e.target.value)}
          placeholder="Cidade"
          style={styles.input}
        />
        <button type="submit" style={styles.button}>
          Cadastrar
        </button>
      </form>
    </div>
  );
}

const styles = {
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f7f7f7",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    margin: "10px 0",
    padding: "10px",
    width: "200px",
  },
  button: {
    padding: "10px 20px",
    cursor: "pointer",
    marginTop: "10px",
  },
};

export default Cadastro;
