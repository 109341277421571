import React, { useState, useEffect, useCallback } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import { DateTime } from "luxon";

ChartJS.register(...registerables);

const GraphComponent = ({ selectedEquipmentNs }) => {
  const [timeRange, setTimeRange] = useState("24h");
  const [rawData, setRawData] = useState([]);
  const [chartBateria, setChartBateria] = useState({
    labels: [],
    datasets: [
      {
        label: "Tensão da Bateria",
        data: [],
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        pointRadius: 0,
      },
    ],
  });

  const processData = useCallback((range, equipamentos) => {
    const now = DateTime.now();
    let filteredData;

    switch (range) {
      case "24h":
        const yesterday = now.minus({ days: 1 });
        filteredData = equipamentos.filter((item) => {
          const itemDateTime = DateTime.fromISO(`${item.data}T${item.hora}`);
          return itemDateTime > yesterday;
        });
        break;
      case "7d":
        const lastWeek = now.minus({ days: 7 });
        filteredData = equipamentos.filter((item) => {
          const itemDateTime = DateTime.fromISO(`${item.data}T${item.hora}`);
          return itemDateTime > lastWeek;
        });
        break;
      case "30d":
        const lastMonth = now.minus({ days: 30 });
        filteredData = equipamentos.filter((item) => {
          const itemDateTime = DateTime.fromISO(`${item.data}T${item.hora}`);
          return itemDateTime > lastMonth;
        });
        break;
      case "today":
        const today = now.toFormat("yyyy-MM-dd");
        filteredData = equipamentos.filter((item) => item.data === today);
        break;
      default:
        filteredData = [];
    }

    const adjustedData = filteredData.map((item) => ({
      ...item,
      bv: item.bv > 14.4 ? 14.4 : item.bv,
    }));

    const labels = adjustedData.map((item) => {
      const itemDateTime = DateTime.fromISO(`${item.data}T${item.hora}`);
      return itemDateTime.toFormat("yyyy-MM-dd HH:mm");
    });

    setChartBateria({
      labels,
      datasets: [
        {
          label: "Tensão da Bateria",
          data: adjustedData.map((item) => item.bv),
          borderColor: "rgb(75, 192, 192)",
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          tension: 0.5,
          pointRadius: 0,
        },
      ],
    });
  }, []);

  const readCSV = useCallback(async (selectedEquipmentNs) => {
    if (!selectedEquipmentNs) return;

    const url = `https://ec2.ebitech.com.br/api/relatorios/logs_${selectedEquipmentNs}.csv`;
    // const url = `http://3.12.177.212/api/relatorios/logs_${selectedEquipmentNs}.csv`;
    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Erro HTTP! status: ${response.status}`);
      }

      const text = await response.text();

      const jsonData = text
        .split("\n")
        .map((line) => {
          try {
            return line.trim() ? JSON.parse(line) : null;
          } catch (error) {
            console.error("Erro ao analisar linha:", line, error);
            return null;
          }
        })
        .filter((item) => item);

      setRawData(jsonData);
      processData(timeRange, jsonData);
    } catch (error) {
      console.error("Erro ao ler o arquivo CSV:", error);
    }
  }, [processData, timeRange]);

  useEffect(() => {
    console.log("Número de série recebido:", selectedEquipmentNs);
    if (selectedEquipmentNs) {
      readCSV(selectedEquipmentNs);
    }
  }, [selectedEquipmentNs, readCSV]);

  useEffect(() => {
    if (rawData.length > 0) {
      processData(timeRange, rawData);
    }
  }, [timeRange, rawData, processData]);

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "auto",
  };

  const graphContainerStyle = {
    width: "90%",
    height: "250px",
    backgroundColor: "White",
    margin: "40px 0",
    padding: "10px",
    marginLeft: "50px",
  };

  const radioLabelStyle = {
    fontSize: "15px",
    marginRight: "10px",
    fontWeight: "300",
  };

  const radioInputStyle = {
    width: "20px",
    height: "20px",
    marginRight: "5px",
  };

  const radioContainerStyle = {
    display: "flex",
    justifyContent: "flex-start",
    width: "80%",
    padding: "10px",
    left: "50%",
    marginTop: "30px",
    marginLeft: "250px",
    alignItems: "center",
    backgroundColor: "#fff",
  };

  const optionsBateria = {
    scales: {
      y: {
        beginAtZero: false,
        grid: {
          drawBorder: false,
          lineWidth: 1,
        },
        max: 14.5,
      },
      x: {
        ticks: {
          maxTicksLimit: 12,
        },
        hitRadius: 30,
        grid: {
          drawBorder: false,
          lineWidth: 1,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            if (tooltipItems.length) {
              const tooltipItem = tooltipItems[0];
              const label = tooltipItem.label;
              if (label) {
                return label;
              }
            }
            return "";
          },
          label: function (context) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y;
            }
            return label;
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div>
      <div style={radioContainerStyle}>
        <label style={radioLabelStyle}>
          <input
            type="radio"
            value="today"
            checked={timeRange === "today"}
            onChange={() => setTimeRange("today")}
            style={radioInputStyle}
          />
          Dia atual
        </label>
        <label style={radioLabelStyle}>
          <input
            type="radio"
            value="24h"
            checked={timeRange === "24h"}
            onChange={() => setTimeRange("24h")}
            style={radioInputStyle}
          />
          Últimas 24 horas
        </label>
        <label style={radioLabelStyle}>
          <input
            type="radio"
            value="7d"
            checked={timeRange === "7d"}
            onChange={() => setTimeRange("7d")}
            style={radioInputStyle}
          />
          Últimos 7 dias
        </label>
        <label style={radioLabelStyle}>
          <input
            type="radio"
            value="30d"
            checked={timeRange === "30d"}
            onChange={() => setTimeRange("30d")}
            style={radioInputStyle}
          />
          Últimos 30 dias
        </label>
      </div>

      <div style={containerStyle}>
        <div style={graphContainerStyle}>
          <Line data={chartBateria} options={optionsBateria} />
        </div>
      </div>
    </div>
  );
};

export default GraphComponent;
