import React from 'react';

const PoliticaPrivacidade = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1 style={{textAlign: "center"}}>Política de Privacidade</h1>
      <p style={{textAlign: "justify"}}>
      <br></br>
      <div>Política de Privacidade do Aplicativo EbiTool</div>
      <br></br>
      <div>Data de vigência: 15/05/2024</div>
      <br></br>

      <div>A política de privacidade descrita abaixo aplica-se ao aplicativo EbiTool, desenvolvido pela SEMALED SINALIZAÇÃO VIÁRIA LTDA. Nosso aplicativo permite aos usuários programarem e realizarem a manutenção dos equipamentos inteligentes da Marca Ebitech3 que utilizam tecnologias de conexão sem fio.</div>
      <br></br>
      <div>1. Informações Coletadas</div>
      <div>Ao utilizar o EbiTool, coletamos apenas dados de telemetria essenciais para o funcionamento eficaz do serviço oferecido pelo aplicativo. Estes dados não incluem informações pessoais dos usuários.</div>
      <br></br>
      <div>2. Uso das Informações</div>
      <div>As informações coletadas são utilizadas exclusivamente para:</div>
      <div>Aperfeiçoar a funcionalidade e eficiência dos equipamentos inteligentes.</div>
      <div>Facilitar a manutenção e o diagnóstico de problemas.</div>
      <div>Assegurar o desempenho adequado das funcionalidades do aplicativo.</div>
      <br></br>
      <div>3. Compartilhamento de Informações</div>
      <div>Não compartilhamos dados coletados com terceiros. Todas as informações são processadas e armazenadas internamente pela SEMALED SINALIZAÇÃO VIÁRIA LTDA.</div>
      <br></br>
      <div>4. Segurança dos Dados</div>
      <br></br>
      <div>Empregamos medidas de segurança adequadas para proteger contra o acesso não autorizado ou alteração dos dados coletados. Isso inclui:</div>
      <div>Protocolos de segurança durante a transmissão de dados.</div>
      <div>Sistemas de autenticação para acesso ao aplicativo.</div>
      <br></br>
      <div>5. Acesso e Controle do Usuário</div>
      <div>Os usuários do aplicativo podem:</div>
      <div>Acessar e gerenciar suas informações de login e senha através do próprio aplicativo.</div>
      <div>Em caso de necessidade de excluir a conta, os usuários podem entrar em contato com o suporte ao cliente pelo e-mail engenharia1@ebitech3.com.br.</div>
      <br></br>
      <div>6. Retenção e Exclusão de Dados</div>
      <div>Os dados de telemetria coletados serão mantidos enquanto forem necessários para as finalidades descritas nesta política. Os dados serão excluídos de forma segura quando não forem mais necessários ou mediante solicitação do usuário.</div>
      <br></br>
      <div>7. Alterações na Política de Privacidade</div>
      <div>Esta política de privacidade pode ser atualizada periodicamente. Qualquer alteração será comunicada aos usuários através do aplicativo ou por e-mail.</div>
      <br></br>
      <div>8. Contato</div>
      <div>Para dúvidas ou preocupações sobre nossa política de privacidade, por favor, contate-nos em:</div>
      <br></br>
      <div>Email: engenharia1@ebitech3.com.br</div>
      <div>Endereço: Rua Bom Jesus de Iguape, 1125 - Hauer, Curitiba - PR - Brasil</div>
      <div>Telefone: +55 41 99787-0070</div>
      </p>
    </div>
  );
};

export default PoliticaPrivacidade;