import React from "react";

const AnaliseRelatorios = ({ analise }) => {
  const formatarDataParaBR = (data) => {
    const [ano, mes, dia] = data.split('-');
    return `${dia}-${mes}-${ano}`;
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <h1 style={{ textAlign: "center" }}>Análise</h1>
      <h3 style={{ textAlign: "center", padding: "10px" }}>Carga da Bateria</h3>
      <div className="d-flex flex-wrap">
        {analise.map((diaAnalise, diaIndex) => (
        <div key={diaIndex} style={{ marginBottom: "20px" }}>
          <h4 style={{ textAlign: "center", padding: "10px" }}>{`Dia: ${formatarDataParaBR(diaAnalise.dia)}`}</h4>
          <div className="d-flex flex-wrap">
            {diaAnalise.intervalos.map((intervalo, index) => (
            <div key={index} className="card" style={{ margin: "5px", padding: "10px", width: "180px", fontSize: "12px" }}>
              <p style={{ textAlign: "center" }}><u>{`${intervalo.inicio.toLocaleTimeString()} - ${intervalo.fim.toLocaleTimeString()}`}</u></p>
              <p><strong>Diferença de Tensão:</strong> {intervalo.diferencaTensao}</p>
              <p><strong>Menor Tensão:</strong> {intervalo.menorTensao}</p>
              <p><strong>Maior Tensão:</strong> {intervalo.maiorTensao}</p>
            </div>
            ))}
          </div>
        </div>
        ))}
      </div>
    </div>
  );
};

export default AnaliseRelatorios;
