import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import { DateTime } from "luxon";
import markerIconGreen from "./Imagens/LocGreen.png";
import markerIconYellow from "./Imagens/LocYellow.png";
import markerIconRed from "./Imagens/LocRed.png";
import markerIconBlack from "./Imagens/LocBlack.png";

const Mapa = ({ localizacoes, onSelectEquipamento, usuario }) => {
  const [mapActive, setMapActive] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [center, setCenter] = useState({ lat: -25.475114, lng: -49.24771 });
  const [activeMarker, setActiveMarker] = useState(null);
  const [equipamentoInput, setEquipamentoInput] = useState("");
  const [equipamentosFiltrados, setEquipamentosFiltrados] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const containerStyle = {
    width: "400px",
    height: "500px",
    marginLeft: "auto",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    backgroundColor: "#f0f0f0",
    marginRight: "30px",
    marginTop: "85px",
    borderRadius: "20px",
  };

  const inputStyle = {
    width: "85%",
    padding: "10px",
    margin: "5px 0 10px 0",
    border: "1px solid #ccc",
    borderRadius: "5px",
    boxSizing: "border-box",
  };

  const mapRef = useRef(null);
  const dropdownRef = useRef(null); // Ref para a lista suspensa

  useEffect(() => {
    if (activeMarker) {
      console.log("Active Marker Set:", activeMarker.nome);
    }
  }, [activeMarker]);

  useEffect(() => {
    if (mapActive) {
      const filteredLocalizacoes = localizacoes.filter((loc) =>
        loc.users.includes(usuario.username)
      );
      const newMarkers = filteredLocalizacoes.map((loc) => {
        const lastCommunication = DateTime.fromISO(`${loc.data}T${loc.hora}`);
        const now = DateTime.now();
        const diff = now.diff(lastCommunication, "minutes").minutes;
        const bateriaString = loc.bateria.replace(',', '.').replace('V', '').trim();
        const bateria = parseFloat(bateriaString);

        let statusIcon;
        if (diff <= 30 && bateria >= 11.9) {
          statusIcon = markerIconGreen;
        } else if ((diff > 30 && diff <= 90) || (bateria >= 11.4 && bateria <= 11.8)) {
          statusIcon = markerIconYellow;
        } else if ((diff > 90 && diff <= 180) || (bateria >= 11.2 && bateria < 11.4)) {
          statusIcon = markerIconRed;
        } else if (diff > 180 || bateria < 11.2) {
          statusIcon = markerIconBlack;
        }

        return {
          position: { lat: loc.lat, lng: loc.lng },
          icon: {
            url: statusIcon,
            scaledSize: new window.google.maps.Size(50, 50),
          },
          nome: loc.nome,
          bateria: loc.bateria,
        };
      });
      setMarkers(newMarkers);
    }
  }, [mapActive, localizacoes, usuario]);

  useEffect(() => {
    if (equipamentoInput) {
      const results = localizacoes
        .filter((loc) =>
          loc.nome.toLowerCase().includes(equipamentoInput.toLowerCase())
        )
        .sort((a, b) => {
          const numberA = parseInt(a.nome.match(/0x(\d+)/)?.[1] || "0", 10);
          const numberB = parseInt(b.nome.match(/0x(\d+)/)?.[1] || "0", 10);
          if (numberA < 10 && numberB >= 10) return -1;
          if (numberA >= 10 && numberB < 10) return 1;

          return numberA - numberB;
        });
      setEquipamentosFiltrados(results);
    } else {
      setEquipamentosFiltrados(localizacoes);
    }
  }, [equipamentoInput, localizacoes]);

  const activateMap = () => {
    if (!mapActive) {
      setMapActive(true);
    }
  };

  const handleCloseInfoWindow = () => {
    setActiveMarker(null);
  };

  const handleMarkerClick = (marker) => {
    handleCloseInfoWindow(null);
    setActiveMarker(marker);
    onSelectEquipamento(marker.nome);
  };

  const handleChangeEquipamento = (e) => {
    const equipamentoEscolhido = localizacoes.find(
      (loc) => loc.nome === e.target.value
    );
    if (equipamentoEscolhido) {
      setCenter({
        lat: equipamentoEscolhido.lat,
        lng: equipamentoEscolhido.lng,
      });
      setActiveMarker({
        ...equipamentoEscolhido,
        position: { lat: equipamentoEscolhido.lat, lng: equipamentoEscolhido.lng },
      });
      onSelectEquipamento(equipamentoEscolhido.nome);
    }
  };

  // Efeito para fechar a lista suspensa ao clicar fora
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setShowDropdown(false); // Fecha a lista se clicar fora
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div style={containerStyle}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          position: "relative",
        }}
      >
        <input
          type="text"
          placeholder="Digite ou selecione o equipamento"
          value={equipamentoInput}
          onFocus={() => setShowDropdown(true)}
          onChange={(e) => {
            setEquipamentoInput(e.target.value);
            setShowDropdown(true);
          }}
          style={inputStyle}
        />
        {showDropdown && equipamentosFiltrados.length > 0 && (
          <ul
            ref={dropdownRef} // Ref para controlar o clique fora
            style={{
              listStyleType: "none",
              padding: 0,
              margin: 0,
              width: "80%",
              border: "1px solid #ccc",
              borderRadius: "5px",
              backgroundColor: "#fff",
              position: "absolute",
              zIndex: 1000,
              maxHeight: "400px",
              overflowY: "auto",
              boxSizing: "border-box",
              top: "50px",
            }}
          >
            {equipamentosFiltrados.map((loc, index) => (
              <li
                key={index}
                onClick={() => {
                  setEquipamentoInput(loc.nome);
                  handleChangeEquipamento({ target: { value: loc.nome } });
                  setEquipamentosFiltrados([]);
                  setShowDropdown(false);
                }}
                style={{
                  padding: "10px",
                  cursor: "pointer",
                  borderBottom: "1px solid #ccc",
                }}
              >
                {loc.nome}
              </li>
            ))}
          </ul>
        )}
        {!mapActive ? (
          <button
            onClick={activateMap}
            style={{
              padding: "10px 20px",
              backgroundColor: "#4CAF50",
              color: "white",
              border: "none",
              borderRadius: "5px",
              fontSize: "16px",
              fontWeight: "bold",
              cursor: "pointer",
              marginTop: "170px",
              transition: "background-color 0.3s",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            }}
          >
            Ativar Mapa
          </button>
        ) : null}
      </div>

      {mapActive && (
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "100%" }}
          center={center}
          zoom={5}
          onLoad={(map) => {
            mapRef.current = map;
          }}
        >
          {markers.map((marker, index) => (
            <Marker
              key={index}
              position={marker.position}
              icon={marker.icon}
              onClick={() => handleMarkerClick(marker)}
            />
          ))}
          {activeMarker && (
            <InfoWindow
              position={activeMarker.position}
              onCloseClick={handleCloseInfoWindow}
            >
              <div>
                <h3>{activeMarker.nome}</h3>
                <h6 style={{ textAlign: "center" }}>{activeMarker.bateria}</h6>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </div>
  );
};

export default Mapa;
