import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LogoIcon from "./Imagens/Ebitech3-logo.png";

function Login({ onLoginSuccess }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [credenciais, setCredenciais] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Use a URL correta do servidor aqui
    fetch('https://ec2.ebitech.com.br/credenciais.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Credenciais recebidas:', data);
        setCredenciais(data);
      })
      .catch(error => console.error('Erro ao buscar credenciais:', error));
  }, []);

  const handleLogin = () => {
    const usuario = credenciais.find(
      (u) => u.username === username && u.password === password
    );
    if (usuario) {
      localStorage.setItem("usuarioLogado", JSON.stringify(usuario));
      onLoginSuccess(usuario);
      navigate("/");
    } else {
      alert("Credenciais inválidas!");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleCadastroClick = () => {
    navigate("/cadastro");
  };

  return (
    <div style={styles.container}>
      <img src={LogoIcon} alt="Logo" style={{ width: "auto", height: "150px", marginBottom: "20px" }} />
      <div style={styles.loginForm}>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Usuário"
          style={styles.input}
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Senha"
          style={styles.input}
        />
        <button onClick={handleLogin} style={styles.button}>
          Entrar
        </button>
        <button onClick={handleCadastroClick} style={styles.button}>
          Cadastro
        </button>
      </div>
    </div>
  );
}

const styles = {
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f7f7f7",
  },
  loginForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    margin: "10px 0",
    padding: "10px",
    width: "200px",
  },
  button: {
    padding: "10px 20px",
    cursor: "pointer",
    marginTop: "10px", // Adiciona espaçamento entre os botões
  },
};

export default Login;
