import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashIcon from "./Imagens/Dash.png";
import RelIcon from "./Imagens/Rel.png";
import EquIcon from "./Imagens/Equi.png";
import AlmIcon from "./Imagens/Alm.png";
import UseIcon from "./Imagens/Use.png";
import ExitIcon from "./Imagens/Exit.png";

const Menu = ({ onLogout }) => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();

  const menuStyle = {
    position: "fixed",
    top: "50%",
    left: "0",
    width: menuVisible ? "230px" : "0",
    overflow: "hidden",
    backgroundColor: "#f0f0f0",
    padding: "35px",
    height: "380px",
    boxSizing: "border-box",
    transition: "width 0.8s",
    marginTop: "0",
    borderRadius: "20px",
    transform: "translate(0, -50%)",
    zIndex: 2,
  };

  const triggerStyle = {
    position: "absolute",
    top: "100px",
    left: "0",
    width: "10px",
    height: "100vh",
    backgroundColor: "transparent",
    zIndex: "1000",
    cursor: "pointer",
  };

  const menuItemStyle = {
    display: "flex",
    alignItems: "center",
    marginBottom: "30px",
    cursor: "pointer",
    textDecoration: "none",
    color: "inherit",
  };

  const textStyle = (itemName) => ({
    fontWeight: hoveredItem === itemName ? "bold" : "normal",
    fontSize: "20px",
    margin: "0",
  });

  const iconStyle = {
    marginRight: "20px",
    marginLeft: "-10px",
    width: "25px",
    height: "25px",
  };

  // Função para lidar com o logout
  const handleLogout = () => {
    localStorage.removeItem("usuarioLogado");
    if (onLogout) {
      onLogout();
    }
    navigate("/login");
    window.location.reload();
  };

  return (
    <>
      <div style={triggerStyle} onMouseEnter={() => setMenuVisible(true)} />
      <div
        style={menuStyle}
        onMouseEnter={() => setMenuVisible(true)}
        onMouseLeave={() => setMenuVisible(false)}
      >
        {/* Dashboard */}
        <Link to="/" style={menuItemStyle}>
          <img src={DashIcon} style={iconStyle} alt="Dashboard" />
          <h2 style={textStyle("dashboard")}>Dashboard</h2>
        </Link>

        {/* Relatórios */}
        <Link to="/relatorios" style={menuItemStyle}>
          <img src={RelIcon} style={iconStyle} alt="Relatórios" />
          <h2 style={textStyle("relatorios")}>Relatórios</h2>
        </Link>

        {/* Equipamentos */}
        <Link to="/equipamentos" style={menuItemStyle}>
          <img src={EquIcon} style={iconStyle} alt="Equipamentos" />
          <h2 style={textStyle("equipamentos")}>Equipamentos</h2>
        </Link>

        {/* Alerta */}
        <Link to="/alertas" style={menuItemStyle}>
          <img src={AlmIcon} style={iconStyle} alt="Alertas" />
          <h2 style={textStyle("alertas")}>Alertas</h2>
        </Link>

        {/* Usuários */}
        <div
          style={menuItemStyle}
          onMouseEnter={() => setHoveredItem("usuarios")}
          onMouseLeave={() => setHoveredItem(null)}
        >
          <img src={UseIcon} style={iconStyle} alt="Usuarios" />
          <h2 style={textStyle("usuarios")}>Usuarios</h2>
        </div>

        {/* Sair */}
        <div
          style={menuItemStyle}
          onMouseEnter={() => setHoveredItem("sair")}
          onMouseLeave={() => setHoveredItem(null)}
          onClick={handleLogout}
        >
          <img src={ExitIcon} style={iconStyle} alt="Sair" />
          <h2 style={textStyle("sair")}>Sair</h2>
        </div>
        {/* Adicione mais itens conforme necessário */}
      </div>
    </>
  );
};

export default Menu;
