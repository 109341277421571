import React, { useState, useEffect } from "react";
import Menu from "./Menu";
import Mapa from "./Mapa";
import GraphComponent from "./GraphComponent";
import Info from "./Info";
import FotoEquipamentos from "./FotoEquipamentos";
import Weather from "./Weather";
import LogoIcon from "./Imagens/Ebitech3-logo.png";

import "bootstrap/dist/css/bootstrap.min.css";

const Dashboard = ({ usuario }) => {
  const [equipamentos, setEquipamentos] = useState([]);
  const [infoSelect, setInfoSelect] = useState(null);   
  const [selectedNs, setSelectedNs] = useState("");
  const [location, setLocation] = useState("");

  // const ec2Url = process.env.NODE_ENV === 'development'
  //   ? process.env.REACT_APP_API_URL_DEV
  //   : process.env.REACT_APP_API_URL_PROD;

  useEffect(() => {
    const fetchEquipamentos = async () => {
      try {
        // const response = await fetch(`http://3.12.177.212/equipamentos.json`);
        const response = await fetch(`https://ec2.ebitech.com.br/equipamentos_ebitech.json`)
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const equipamentosAutorizados = data.filter((equip) =>
          equip.users.includes(usuario.username)
        );
        setEquipamentos(equipamentosAutorizados);
      } catch (error) {
        console.error("Erro ao carregar os equipamentos:", error);
      }
    };
  
    fetchEquipamentos();
  // }, [usuario, ec2Url]);
  }, [usuario]);

  useEffect(() => {
    console.log("Estado atual de infoSelect:", infoSelect);
  }, [infoSelect]);

  const selecionarEquipamento = (nomeEquipamento) => {
    const equipamento = equipamentos.find(
      (equip) => equip.nome === nomeEquipamento
    );
    if (equipamento) {
      setInfoSelect(equipamento);
      setSelectedNs(equipamento.nome);
      setLocation(equipamento.localizacao);
      console.log("Equipamento selecionado:", equipamento.nome);
    }
  };

  return (
    <div className="container-fluid h-100">
      <img
        src={LogoIcon}
        alt="Logo"
        style={{
          position: "absolute",
          top: 20,
          left: 5,
          width: "144px",
          height: "40px",
        }}
      />
      <div className="row h-100">
        <div className="col-1">
          <Menu />
        </div>
        <div className="col-4">
          <Info equipamento={infoSelect} usuario={usuario} />
        </div>
        <div className="col-3">
          <FotoEquipamentos equipamento={infoSelect} />
        </div>
        <div className="col-4">
          <Mapa
            localizacoes={equipamentos}
            onSelectEquipamento={selecionarEquipamento}
            usuario={usuario}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-10">
          <GraphComponent selectedEquipmentNs={selectedNs} usuario={usuario} />
        </div>
        <div className="col-2">
          <Weather location={location} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
