import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { DateTime } from "luxon";
import wifiIcon from "./Imagens/Wifi.png";
import loraIcon from "./Imagens/lorawan.png";
import trueIcon from "./Imagens/True.png";
import falseIcon from "./Imagens/False.png";
import alertIcon from "./Imagens/Alert.png";
import LogoIcon from "./Imagens/Ebitech3-logo.png";
import { Button, Modal, Form } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const AscIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="18 15 12 9 6 15"></polyline>
  </svg>
);

const DescIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="6 9 12 15 18 9"></polyline>
  </svg>
);

const SortButton = ({ column, sortConfig, onSortChange }) => {
  const isActive = sortConfig.key === column;
  const isAsc = sortConfig.direction === "ascending";
  const icon = isActive ? (isAsc ? <AscIcon /> : <DescIcon />) : <AscIcon />;
  return (
    <button className="sort-button" onClick={() => onSortChange(column)}>
      {icon}
    </button>
  );
};

const Equipamentos = ({ usuario }) => {
  const [equipamentos, setEquipamentos] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const now = DateTime.now();
  const [filterNome, setFilterNome] = useState("");
  const [filterUC, setFilterUC] = useState("");
  const [filterBateria, setFilterBateria] = useState("");
  const [filterPainel, setFilterPainel] = useState("");
  const [filterFirmware, setFilterFirmware] = useState("");
  const [filterCidade, setFilterCidade] = useState("");
  const [filterEndereco, setFilterEndereco] = useState("");
  const [filterCoord, setFilterCoord] = useState("");
  const [showCityStateModal, setShowCityStateModal] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showCoordinatesModal, setShowCoordinatesModal] = useState(false);
  const [selectedEquip, setSelectedEquip] = useState(null);
  const [newCity, setNewCity] = useState("");
  const [newState, setNewState] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [newLat, setNewLat] = useState("");
  const [newLng, setNewLng] = useState("");

  // const ec2Url = process.env.NODE_ENV === 'development'
  //   ? process.env.REACT_APP_API_URL_DEV
  //   : process.env.REACT_APP_API_URL_PROD;

  useEffect(() => {
    // fetch(`http://3.12.177.212/equipamentos.json`)
    fetch(`https://ec2.ebitech.com.br/equipamentos_ebitech.json`)
    // fetch(`http://localhost:8080/equipamentos.json`)
      .then((response) => response.json())
      .then((data) => {
        const equipamentosAutorizados = data.filter((equip) =>
          equip.users.includes(usuario.username)
        );
        setEquipamentos(equipamentosAutorizados);
      })
      .catch((error) =>
        console.error("Erro ao carregar os equipamentos:", error)
      );
    }, [usuario]);
  // }, [usuario, ec2Url]);

  const onSortChange = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    } else {
      direction = "ascending";
    }
    setSortConfig({ key, direction });
  };

  const getStatusValue = (equip) => {
    const lastCommunication = DateTime.fromISO(`${equip.data}T${equip.hora}`);
    const diff = DateTime.now().diff(lastCommunication, "minutes").minutes;
  
    if (diff <= 30) {
      return 1; // Ativo
    } else if (diff > 30 && diff <= 180) {
      return 2; // Alerta
    } else {
      return 3; // Inativo
    }
  };

  const sortedAndFilteredEquipamentos = React.useMemo(() => {
    return equipamentos
      .filter(equip => {
        return equip.nome.toLowerCase().includes(filterNome.toLowerCase()) &&
               equip.data.toLowerCase().includes(filterUC.toLowerCase()) &&
               equip.bateria.toLowerCase().includes(filterBateria.toLowerCase()) &&
               equip.painel.toLowerCase().includes(filterPainel.toLowerCase()) &&
               equip.firmware.toLowerCase().includes(filterFirmware.toLowerCase()) &&
               equip.endereço.toLowerCase().includes(filterEndereco.toLowerCase()) &&
               equip.cidade.toLowerCase().includes(filterCidade.toLowerCase());
      })
      .sort((a, b) => {
        let aValue, bValue;
        if (sortConfig.key === "status") {
          aValue = getStatusValue(a);
          bValue = getStatusValue(b);
        } else if (sortConfig.key === "conexão.WiFi") {
          aValue = a.conexão.WiFi === "Sim" ? 1 : 0;
          bValue = b.conexão.WiFi === "Sim" ? 1 : 0;
        } else {
          aValue = a[sortConfig.key];
          bValue = b[sortConfig.key];
        }
  
        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
  }, [equipamentos, sortConfig, filterNome, filterUC, filterBateria, filterPainel, filterFirmware, filterCidade, filterEndereco]);

  const containerStyle = {
    padding: "0px",
    maxWidth: "flex",
    margin: "0px auto 0 100px",
  };

  const pieChartStyle = {
    width: "250px",
    height: "250px",
  };

  // Processar dados para gráficos
  const connectionData = equipamentos.reduce(
    (acc, equip) => {
      equip.conexão.WiFi === "Sim" ? acc.wifi++ : acc.lora++;
      return acc;
    },
    { wifi: 0, lora: 0 }
  );

  const statusData = equipamentos.reduce(
    (acc, equip) => {
      const lastCommunication = DateTime.fromISO(`${equip.data}T${equip.hora}`);
      const diff = now.diff(lastCommunication, "minutes").minutes;

      if (diff <= 30) {
        acc.active += 1;
        console.log("ativos:", acc.active);
      } else if (diff > 30 && diff <= 180) {
        acc.alert += 1;
        console.log("alerta:", acc.alert);
      } else {
        acc.inactive += 1;
        console.log("inativos:", acc.inactive);
      }
      return acc;
    },
    { active: 0, alert: 0, inactive: 0 }
  );

  // Dados para gráficos de pizza
  const connectionChartData = {
    labels: ["WiFi", "LoRa"],
    datasets: [
      {
        label: "Conexões",
        data: [connectionData.wifi, connectionData.lora],
        backgroundColor: ["#36A2EB", "#FF6384"],
      },
    ],
  };

  const statusChartData = {
    labels: ["Ativo", "Alerta", "Inativo"],
    datasets: [
      {
        label: "Status",
        data: [statusData.active, statusData.alert, statusData.inactive],
        backgroundColor: ["#46BFBD", "#FDB45C", "#F7464A"],
        hoverOffset: 4,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      datalabels: {
        formatter: (value, context) => {
          const sum = context.dataset.data.reduce((acc, val) => acc + val, 0);
          const percentage = ((value / sum) * 100).toFixed(2) + "%";
          return percentage;
        },
        color: "#fff",
        font: {
          weight: "bold",
        },
      },
      legend: {
        display: true,
        position: 'top',
        labels: {
          generateLabels: (chart) => {
            const data = chart.data;
            if (data.labels.length && data.datasets.length) {
              return data.labels.map((label, i) => {
                const meta = chart.getDatasetMeta(0);
                const total = data.datasets[0].data.reduce((acc, val) => acc + val, 0);
                const value = data.datasets[0].data[i];
                const percentage = ((value / total) * 100).toFixed(2);
                return {
                  text: `${label}: ${value} (${percentage}%)`,
                  fillStyle: data.datasets[0].backgroundColor[i],
                  hidden: isNaN(data.datasets[0].data[i]) || meta.data[i].hidden,
                  lineCap: meta.lineCap,
                  lineDash: meta.lineDash,
                  lineDashOffset: meta.lineDashOffset,
                  lineJoin: meta.lineJoin,
                  lineWidth: meta.lineWidth,
                  strokeStyle: meta.strokeStyle,
                  pointStyle: meta.pointStyle,
                  rotation: meta.rotation,
                };
              });
            }
            return [];
          },
        },
      },
    },
  };

  // Componente de estilo
  const chartContainerStyle = {
    display: "flex",
    justifyContent: "space-around",
    marginBottom: "20px",
  };

  const titleStyle = {
    textAlign: "center",
    marginBottom: "10px",
    fontWeight: "bold",
  };

  const tableCellStyle = {
    textAlign: "center",
    verticalAlign: "middle",
    fontSize: "14px",
    width: "80px",
  };

  const inputStyle = {
    width: "80px",
    fontSize: "12px",
  };

  const calculateStatusIcon = (equip) => {
    const lastCommunication = DateTime.fromISO(`${equip.data}T${equip.hora}`);
    const diff = DateTime.now().diff(lastCommunication, "minutes").minutes;

    if (diff <= 30) {
      return trueIcon;
    } else if (diff > 30 && diff <= 180) {
      return alertIcon;
    } else {
      return falseIcon;
    }
  };

  const handleEditCityStateClick = (equip) => {
    setSelectedEquip(equip);
    setNewCity(equip.cidade);
    setNewState(equip.estado);
    setShowCityStateModal(true);
  };

  const handleEditAddressClick = (equip) => {
    setSelectedEquip(equip);
    setNewAddress(equip.endereço);
    setShowAddressModal(true);
  };

  const handleEditCoordinatesClick = (equip) => {
    setSelectedEquip(equip);
    setNewLat(equip.lat);
    setNewLng(equip.lng);
    setShowCoordinatesModal(true);
  };

  const handleSaveCityStateClick = () => {
    fetch(`https://ec2.ebitech.com.br/flask_script_equip/update_city_state`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: selectedEquip.id, newCity: newCity, newState: newState }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // Atualize a lista de equipamentos localmente
          setEquipamentos((prevEquipamentos) =>
            prevEquipamentos.map((equip) =>
              equip.id === selectedEquip.id ? { ...equip, cidade: newCity, estado: newState } : equip
            )
          );
        }
        setShowCityStateModal(false);
      })
      .catch((error) => console.error("Erro ao atualizar a cidade e/ou estado:", error));
  };

  const handleSaveAddressClick = () => {
    fetch(`https://ec2.ebitech.com.br/flask_script_equip/update_address`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: selectedEquip.id, newAddress: newAddress }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // Atualize a lista de equipamentos localmente
          setEquipamentos((prevEquipamentos) =>
            prevEquipamentos.map((equip) =>
              equip.id === selectedEquip.id ? { ...equip, endereço: newAddress } : equip
            )
          );
        }
        setShowAddressModal(false);
      })
      .catch((error) => console.error("Erro ao atualizar o endereço:", error));
  };

  const handleSaveCoordinatesClick = () => {
    fetch(`https://ec2.ebitech.com.br/flask_script_equip/update_coord`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: selectedEquip.id, newLat: parseFloat(newLat), newLng: parseFloat(newLng) }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // Atualize a lista de equipamentos localmente
          setEquipamentos((prevEquipamentos) =>
            prevEquipamentos.map((equip) =>
              equip.id === selectedEquip.id ? { ...equip, lat: parseFloat(newLat), lng: parseFloat(newLng) } : equip
            )
          );
        }
        setShowCoordinatesModal(false);
      })
      .catch((error) => console.error("Erro ao atualizar as coordenadas:", error));
  };

  return (
    <div>
      <img
        src={LogoIcon}
        alt="Logo"
        style={{
          position: "absolute",
          top: 20,
          left: 5,
          width: "144px",
          height: "40px",
        }}
      />
      <div style={chartContainerStyle}>
        <div style={pieChartStyle}>
          <div style={titleStyle}>Conexão</div>
          <Pie data={connectionChartData} options={chartOptions} />
        </div>
        <div style={pieChartStyle}>
          <div style={titleStyle}>Status</div>
          <Pie data={statusChartData} options={chartOptions} />
        </div>
      </div>
      <div style={containerStyle}>
        <h2 style={{ textAlign: "center" }}>Lista de Equipamentos</h2>
        <table className="table">
          <thead>
            <tr>
              <th style={tableCellStyle}> Número de Série <SortButton column="nome" sortConfig={sortConfig} onSortChange={onSortChange} /> </th>
              <th style={tableCellStyle}> Conexão <SortButton column="conexão.WiFi" sortConfig={sortConfig} onSortChange={onSortChange} /> </th>
              <th style={tableCellStyle}> Status <SortButton column="status" sortConfig={sortConfig} onSortChange={onSortChange} /> </th>
              <th style={tableCellStyle}> Endereço <SortButton column="endereço" sortConfig={sortConfig} onSortChange={onSortChange} /> </th>
              <th style={tableCellStyle}> Cidade <SortButton column="cidade" sortConfig={sortConfig} onSortChange={onSortChange} /> </th>
              <th style={tableCellStyle}> Bateria</th>
              <th style={tableCellStyle}> Painel</th>
              <th style={tableCellStyle}> Firmware <SortButton column="firmware" sortConfig={sortConfig} onSortChange={onSortChange} /> </th>
              <th style={tableCellStyle}> Última Comunicação <SortButton column="data" sortConfig={sortConfig} onSortChange={onSortChange} /> </th>
              <th style={tableCellStyle}> Coordenadas <SortButton column="coordenadas" sortConfig={sortConfig} onSortChange={onSortChange} /> </th>
            </tr>
            <tr>
              <th style={tableCellStyle}><input type="text" style={inputStyle} value={filterNome} onChange={e => setFilterNome(e.target.value)} /></th>
              <th></th>
              <th></th>
              <th style={tableCellStyle}><input type="text" style={inputStyle} value={filterEndereco} onChange={e => setFilterEndereco(e.target.value)} /></th>
              <th style={tableCellStyle}><input type="text" style={inputStyle} value={filterCidade} onChange={e => setFilterCidade(e.target.value)} /></th>
              <th style={tableCellStyle}><input type="text" style={inputStyle} value={filterBateria} onChange={e => setFilterBateria(e.target.value)} /></th>
              <th style={tableCellStyle}><input type="text" style={inputStyle} value={filterPainel} onChange={e => setFilterPainel(e.target.value)} /></th>
              <th style={tableCellStyle}><input type="text" style={inputStyle} value={filterFirmware} onChange={e => setFilterFirmware(e.target.value)} /></th>
              <th style={tableCellStyle}><input type="text" style={inputStyle} value={filterUC} onChange={e => setFilterUC(e.target.value)} /></th>
              <th style={tableCellStyle}><input type="text" style={inputStyle} value={filterCoord} onChange={e => setFilterCoord(e.target.value)} /></th>
            </tr>
          </thead>
          <tbody>
            {sortedAndFilteredEquipamentos.map((equip) => {
              const statusIcon = calculateStatusIcon(equip);

              return (
                <tr key={equip.id}>
                  <td style={tableCellStyle}>{equip.nome}</td>
                  <td style={tableCellStyle}>
                    <img
                      src={equip.conexão.WiFi === "Sim" ? wifiIcon : loraIcon}
                      alt={equip.conexão.WiFi === "Sim" ? "WiFi" : "Lora"}
                      style={{ width: "30px" }}
                    />
                  </td>
                  <td style={tableCellStyle}>
                    <img
                      src={statusIcon}
                      alt="Status"
                      style={{ width: "30px" }}
                    />
                  </td>
                  <td style={tableCellStyle}>
                    {equip.endereço}
                    {usuario.username === "master" && (
                      <Button
                      variant="secondary"
                      size="sm"
                      onClick={() => handleEditAddressClick(equip)}
                      style={{ marginLeft: "10px" }}
                      >
                        Editar Endereço
                      </Button>
                    )}</td>
                  <td style={tableCellStyle}>
                    {`${equip.cidade}, ${equip.estado}`}
                    {usuario.username === "master" && (
                      <Button
                      variant="secondary"
                      size="sm"
                      onClick={() => handleEditCityStateClick(equip)}
                      style={{ marginLeft: "10px" }}
                      >
                        Editar Cidade, Estado
                      </Button>
                    )}
                  </td>
                  <td style={tableCellStyle}>{equip.bateria}</td>
                  <td style={tableCellStyle}>{equip.painel}</td>
                  <td style={tableCellStyle}>{equip.firmware}</td>
                  <td
                    style={tableCellStyle}
                  >{`${equip.data} ${equip.hora}`}</td>
                  <td>
                  {`Lat: ${equip.lat}, Lng: ${equip.lng}`}
                  {usuario.username === "master" && (
                    <Button variant="secondary" size="sm" onClick={() => handleEditCoordinatesClick(equip)} style={{ marginLeft: "10px" }}>
                      Editar Coordenadas
                    </Button>
                  )}
                </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* Modal para editar cidade e estado*/}
        <Modal show={showCityStateModal} onHide={() => setShowCityStateModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Editar Cidade e Estado</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formCity">
              <Form.Label>Nova Cidade</Form.Label>
              <Form.Control type="text" value={newCity} onChange={(e) => setNewCity(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formState">
              <Form.Label>Novo Estado</Form.Label>
              <Form.Control type="text" value={newState} onChange={(e) => setNewState(e.target.value)} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowCityStateModal(false)}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleSaveCityStateClick}>
              Salvar
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal para editar endereço */}
        <Modal show={showAddressModal} onHide={() => setShowAddressModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Editar Endereço</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formAddress">
              <Form.Label>Novo Endereço</Form.Label>
              <Form.Control type="text" value={newAddress} onChange={(e) => setNewAddress(e.target.value)} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAddressModal(false)}>Cancelar</Button>
            <Button variant="primary" onClick={handleSaveAddressClick}>Salvar</Button>
          </Modal.Footer>
        </Modal>

        {/* Modal para editar coordenadas */}
        <Modal show={showCoordinatesModal} onHide={() => setShowCoordinatesModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Editar Coordenadas</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formLat">
              <Form.Label>Nova Latitude</Form.Label>
              <Form.Control type="number" step="any" value={newLat} onChange={(e) => setNewLat(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formLng">
              <Form.Label>Nova Longitude</Form.Label>
              <Form.Control type="number" step="any" value={newLng} onChange={(e) => setNewLng(e.target.value)} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowCoordinatesModal(false)}>Cancelar</Button>
            <Button variant="primary" onClick={handleSaveCoordinatesClick}>Salvar</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Equipamentos;
