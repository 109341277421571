import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { LoadScript } from "@react-google-maps/api";
import LoginPage from "./Login";
import Menu from "./Menu";
import MapPage from "./Dashboard";
import Relatorios from "./Relatorios";
import Equipamentos from "./Equipamentos";
import Alertas from "./Alertas";
import PoliticaPrivacidade from "./PoliticaPrivacidade";
import Cadastro from "./Cadastro";  // Importe o componente Cadastro

import '@fortawesome/fontawesome-free/css/all.min.css';

function Logout({ onLogout }) {
  const navigate = useNavigate();
  
  useEffect(() => {
    onLogout();
    navigate("/login");
  }, [onLogout, navigate]);
  
  return null;
}

function App() {
  const [usuario, setUsuario] = useState(null);

  useEffect(() => {
    const usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
    if (usuarioLogado) {
      setUsuario(usuarioLogado);
    }
  }, []);

  const handleLoginSuccess = (usuarioLogado) => {
    setUsuario(usuarioLogado);
    localStorage.setItem("usuarioLogado", JSON.stringify(usuarioLogado));
  };

  const handleLogout = () => {
    setUsuario(null);
    localStorage.removeItem("usuarioLogado");
  };

  return (
    <div>
      <Router>
        <LoadScript googleMapsApiKey="AIzaSyBJ68Y9NlgsorHgKiH4hCNc47oL9vGKwHU">
          <Routes>
            <Route path="/politica-de-privacidade" element={<PoliticaPrivacidade />} />
            <Route path="/cadastro" element={<Cadastro />} />  {/* Adicione a rota para Cadastro */}
            {usuario ? (
              <>
                <Route path="/" element={<><Menu onLogout={handleLogout} /><MapPage usuario={usuario} /></>} />
                <Route path="/relatorios" element={<><Menu onLogout={handleLogout} /><Relatorios usuario={usuario} /></>} />
                <Route path="/equipamentos" element={<><Menu onLogout={handleLogout} /><Equipamentos usuario={usuario} /></>} />
                <Route path="/alertas" element={<><Menu onLogout={handleLogout} /><Alertas usuario={usuario} /></>} />
                <Route path="/logout" element={<Logout onLogout={handleLogout} />} />
              </>
            ) : (
              <>
                <Route path="/login" element={<LoginPage onLoginSuccess={handleLoginSuccess} />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </>
            )}
          </Routes>
        </LoadScript>
      </Router>
    </div>
  );
}

export default App;
