import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Weather.css';

const api = {
    key: "f3198196c9e4bfb08e5cad6fcd1a69b5",
    base: "https://api.openweathermap.org/data/2.5/",
    lang: "pt_br",
    units: "metric",
    geoBase: "https://api.openweathermap.org/geo/1.0/direct"
};

const Weather = () => {
    const [weatherData, setWeatherData] = useState(null);
    const [location, setLocation] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [lat, setLat] = useState(null);
    const [lon, setLon] = useState(null);
    const [tempUnit, setTempUnit] = useState('°C');
    const [geoDenied, setGeoDenied] = useState(false);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(setPosition, showError);
        } else {
            alert('Navegador não suporta geolocalização');
        }
    }, []);

    useEffect(() => {
        if (lat && lon) {
            coordResults(lat, lon);
        }
    }, [lat, lon]);

    const setPosition = (position) => {
        setLat(position.coords.latitude);
        setLon(position.coords.longitude);
    };

    const showError = (error) => {
        try {
            if (error.code === error.PERMISSION_DENIED) {
                setGeoDenied(true);
            } else {
                console.log("Erro1");
                alert(`Erro: ${error.message}`);
            }
        } catch (e) {
            console.log("Erro2");
            alert(`Erro inesperado: ${e.message}`);
        }
    };

    const coordResults = async (lat, lon) => {
        try {
            const response = await axios.get(`${api.base}weather`, {
                params: {
                    lat: lat,
                    lon: lon,
                    lang: api.lang,
                    units: api.units,
                    APPID: api.key,
                },
            });
            setWeatherData(response.data);
        } catch (error) {
            console.log("Erro3");
            alert(error.message);
        }
    };

    const buildQuery = () => {
        let query = location;
        if (state) {
            query += `,${state}`;
        }
        if (country) {
            query += `,${country}`;
        }
        return query;
    };

    const searchResults = async () => {
        const query = buildQuery();
        try {
            const geoResponse = await axios.get(`${api.geoBase}`, {
                params: {
                    q: query,
                    limit: 1,
                    appid: api.key,
                },
            });

            if (geoResponse.data && Array.isArray(geoResponse.data) && geoResponse.data.length > 0) {
                const { lat, lon, name, state, country } = geoResponse.data[0];
                if (lat && lon && name && country) {
                    setLat(lat);
                    setLon(lon);
                    setState(state || '');
                    setCountry(country || '');
                    setWeatherData(prevData => ({
                        ...prevData,
                        name: name,
                        state: state,
                        country: country
                    }));
                    console.log("Cidade: ", name);
                    console.log("Estado: ", state);
                    console.log("País: ", country);
                    // coordResults(lat, lon);
                } else {
                    alert('Dados incompletos retornados pela API');
                }
            } else {
                alert('Localização não encontrada');
            }
        } catch (error) {
            console.log("Erro4");
            alert(error.message);
        }
    };

    const handleSearch = () => {
        searchResults();
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            searchResults();
        }
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const dateBuilder = (d) => {
        let days = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
        let months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

        let day = days[d.getDay()];
        let date = d.getDate();
        let month = months[d.getMonth()];
        let year = d.getFullYear();

        return `${day}, ${date} ${month} ${year}`;
    };

    const changeTemp = () => {
        if (weatherData) {
            let temp_number_now = weatherData.main.temp;
            if (tempUnit === '°C') {
                let f = (temp_number_now * 1.8) + 32;
                setTempUnit('°F');
                setWeatherData({ ...weatherData, main: { ...weatherData.main, temp: Math.round(f) } });
            } else {
                let c = (temp_number_now - 32) / 1.8;
                setTempUnit('°C');
                setWeatherData({ ...weatherData, main: { ...weatherData.main, temp: Math.round(c) } });
            }
        }
    };

    return (
        <div className="weather-container">
            <div className="weather-card">
                <div className="weather-card__header">
                    TEMPO E TEMPERATURA
                </div>
                <div className="weather-card__body">
                    {geoDenied ? (
                        <div className="weather-alert">
                            Permissão de geolocalização negada. Por favor, insira sua localização manualmente.
                        </div>
                    ) : null}
                    <div className="weather-location">{weatherData ? `${weatherData.name}, ${weatherData.state || ''}, ${weatherData.sys?.country}` : 'Cidade, Estado, País'}</div>
                    <div className="weather-date">{dateBuilder(new Date())}</div>
                    <div className="weather-icon">
                        {weatherData && weatherData.weather && weatherData.weather[0] ? (
                            <img src={require(`./icons/${weatherData.weather[0].icon}.png`)} alt="Weather Icon" />
                        ) : (
                            <img src={require('./icons/unknown.png')} alt="Weather Icon" />
                        )}
                    </div>
                    <div className="weather-temp" onClick={changeTemp}>
                    <div className="weather-temp__number">{weatherData && weatherData.main ? Math.round(weatherData.main.temp) : '--'}</div>
                        <span className="weather-temp__unit">°C</span>
                    </div>
                    <div className="weather-description">{weatherData && weatherData.weather && weatherData.weather[0] ? capitalizeFirstLetter(weatherData.weather[0].description) : 'Nublado'}</div>
                    <div className="weather-range">{weatherData && weatherData.main ? `${Math.round(weatherData.main.temp_min)}°C / ${Math.round(weatherData.main.temp_max)}°C` : '22°C / 23°C'}</div>
                </div>
                <div className="weather-card__footer">
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Digite o nome da cidade"
                            aria-label="Digite o nome da cidade"
                            aria-describedby="button-addon2"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Digite o nome do estado"
                            aria-label="Digite o nome do estado"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Digite o nome do país"
                            aria-label="Digite o nome do país"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                    <div className="input-group-append">
                            <button className="btn btn-outline-warning text-dark" type="button" id="button-addon2" onClick={handleSearch}>
                                <i className="fas fa-search"></i>
                            </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Weather;