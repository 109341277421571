import React from "react";
import EquIcon from "./Imagens/Equi.png";
import wifiIcon from "./Imagens/Wifi.png";
import loraIcon from "./Imagens/lorawan.png";

const Info = ({ equipamento }) => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const inputStyle = {
    width: "100%",
    padding: "20px",
    backgroundColor: "#f0f0f0",
    borderRadius: "20px",
    marginTop: "85px",
    marginLeft: "10px",
  };

  const iconStyle = {
    marginRight: "20px",
    marginLeft: "-10px",
    width: "25px",
    height: "25px",
  };

  const conexaoStyle = {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  };

  const conexaoTituloStyle = {
    width: "100%",
    textAlign: "center",
    marginBottom: "10px",
  };

  if (!equipamento) {
    return (
      <div style={containerStyle}>
        <div style={inputStyle}>
          <h3>
            <center>Selecione um equipamento para ver os detalhes</center>
          </h3>
        </div>
      </div>
    );
  }

  const { WiFi } = equipamento.conexão || {
    WiFi: "Indisponível",
  };

  const conexaoImagem = WiFi === "Sim" ? wifiIcon : loraIcon;
  const conexaoTexto = WiFi === "Sim" ? "WiFi" : "";

  return (
    <div style={containerStyle}>
      <div style={inputStyle}>
        <h3>
          <center>
            <img src={EquIcon} style={iconStyle} alt="Equipamentos" />
            {equipamento.nome}
          </center>
        </h3>
        <div style={{ margin: "30px 0" }} />
        <div style={conexaoStyle}>
          <p>
            <strong>Bateria:</strong> {equipamento.bateria}
          </p>
          <p>
            <strong>Data:</strong> {equipamento.data}
          </p>
        </div>
        <div style={conexaoStyle}>
          <p>
            <strong>Painel:</strong> {equipamento.painel}
          </p>
          <p>
            <strong>Hora:</strong> {equipamento.hora}
          </p>
        </div>
        <div style={conexaoStyle}>
          <p>
            <strong>Cidade:</strong> {`${equipamento.cidade}, ${equipamento.estado}`}
          </p>
        </div>

        <div style={conexaoTituloStyle}>
          <strong>CONEXÃO</strong>
        </div>
        <div style={conexaoStyle}>
          <img src={conexaoImagem} style={{ width: "50px" }} alt={conexaoTexto} />
        </div>
        <div style={conexaoStyle}>
          <p>
            <strong>{conexaoTexto}</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Info;
